<template>
  <div>
    <Header
      :showBackButton="true"
      :showLogo="false"
      :menuColor="'#fff'"
      class="page-anim1"
    />

    <transition enter-active-class="animated fadeInDown">
      <div
        v-show="post.image"
        class="img-holder"
        :style="`background-image:url(${post.image})`"
      ></div>
    </transition>

    <div v-show="loading" class="flex-center desktop12 tablet12 mobile12 mt-3">
      <div class="loading2"></div>
    </div>

    <div v-show="!loading" class="holder container">
      <div class="main">
        <div class="container">
          <span class="timestamp">{{
            post.timestamp | moment("DD/MM/YYYY")
          }}</span>

          <h1 class="title">{{ post.title }}</h1>
          <p class="desc" v-html="post.description"></p>
        </div>
      </div>

      <div class="related container">
        <div v-show="relatedPosts.length > 0" class="title-holder">
          <h1>Relacionados</h1>
        </div>

        <div class="related-holder">
          <PostCard
            v-for="post in relatedPosts"
            :key="post.id"
            :id="post.id"
            :slug="post.slug"
            :title="post.title"
            :description="post.description"
            :image="post.image"
            :timestamp="post.timestamp"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import PostCard from "../components/PostCard";
import Liked from "vue-material-design-icons/ThumbUp.vue";
import Favorited from "vue-material-design-icons/Heart.vue";
import Book from "vue-material-design-icons/BookOpenVariant.vue";
import Bowl from "vue-material-design-icons/BowlMix.vue";

export default {
  name: "Home",
  components: {
    Header,
    PostCard,
    Liked,
    Favorited,
    Book,
    Bowl,
  },
  data() {
    return {
      loading: true,
      post: {},
      relatedPosts: [],
    };
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      http
        .get(`post/details?id=${this.$route.params.id}`)
        .then((response) => {
          this.post = response.data;
          this.relatedPosts = this.post.relatedPosts;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.img-holder {
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 250px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, #000000bb, transparent);
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.timestamp {
  position: relative;
  display: block;
  width: 100%;
  font-style: italic;
  margin-top: 15px;
}

.title {
  position: relative;
  color: var(--dark4);
  margin: 15px 0 0 0;
}

.desc {
  position: relative;
  font-size: 1.1em;
  margin: 15px 0 0 0;
}

.title-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  &.prepare {
    h1 {
      margin-top: 7px;
    }
  }
  span {
    position: relative;
    margin-right: 15px;
  }
  svg {
    color: var(--secondary);
  }
  h1 {
    font-family: fontLight;
    font-size: 1.2em;
    color: var(--secondary);
  }
}

.material-design-icon.icon {
  width: 2.4em;
  height: 2.4em;
}

.material-design-icon.icon > .material-design-icon__svg {
  width: 2.4em;
  height: 2.4em;
}

.ingredients {
  position: relative;
  width: 100%;
  margin-top: 10px;
  li {
    position: relative;
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 1.1em;
      b {
        font-family: fontMedium;
        font-weight: normal;
      }
      &.price {
        color: var(--primary);
        font-size: 1.15em;
      }
    }
  }
}

.holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding-bottom: 95px;
  @media screen and (max-width: 890px) {
    grid-template-columns: 1fr;
  }
}

.related-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 15px;
}

@media screen and (min-width: 1160px) {
  .img-holder {
    height: 450px;
  }
}
</style>
